<template>
  <el-dialog
    :title="dialogType === 'edit' ? '编辑菜单' : '新增菜单'"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    @close="$emit('update:dialogVisible', false)"
    width="30%"
  >
    <div class="dialog-content">
      <el-form
        ref="form"
        :model="form"
        size="mini"
        :rules="rules"
        :label-position="'right'"
        label-width="auto"
      >
        <el-form-item label="上级类目">
          <el-select
            ref="selectClassify"
            v-model="form.parentName"
            placeholder=""
            style="width: 100%"
            clearable
          >
            <el-option :value="form.parentId" style="height: auto">
              <tree
                ref="tree"
                v-if="showTree"
                :tree-type="'form'"
                :initFalse="initFalse"
                :params="treeParams"
                :tData="treeData"
                :api-function="this.$api.sysManage.menuTreeData"
                @nodeClick="handlerNodeClick"
              />
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单标题" prop="menuName">
          <el-input v-model="form.menuName" clearable></el-input>
        </el-form-item>
        <el-form-item label="菜单图标">
          <el-input v-model="form.icon" clearable></el-input>
        </el-form-item>
        <el-form-item label="组件路径" prop="url">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="权限标识" prop="perms">
          <el-input v-model="form.perms"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="菜单状态" prop="visible">
              <el-radio-group v-model="form.visible">
                <el-radio-button label="0">激活</el-radio-button>
                <el-radio-button label="1">禁用</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="菜单排序">
          <el-input v-model.number="form.orderNum"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:dialogVisible', false)"
        >取 消</el-button
      >
      <el-button size="small" type="primary" @click="saveFn">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import tree from '../tree.vue';
import { transformTree } from '@/utils/utils.js';
export default {
  name: 'addMenu',
  props: {
    dialogVisible: {
      type: Boolean,
      require,
    },
    menuData: {
      type: Object,
      default: () => ({}),
    },
    dialogType: {
      type: String,
      require,
    },
  },
  components: {
    tree,
  },
  data() {
    return {
      dialogFormVisible: this.dialogVisible,
      treeParams: {},
      form: {
        menuName: '',
        icon: '',
        url: '',
        perms: '',
        orderNum: '',
        parentName: '',
        parentId: '',
        visible: '0',
        menuId: '',
      },
      initFalse: false,
      showTree: true,
      treeData: [],
      rules: {
        menuName: [{ required: true, message: '请输入菜单标题' }],
        url: [{ required: true, message: '请输入组件路径' }],
        perms: [{ required: true, message: '请输入权限标识' }],
        visible: [{ required: true, message: '请选择菜单状态' }],
      },
    };
  },
  mounted() {
    //如果是编辑的话放数据
    if (this.dialogType == 'edit') {
      for (let key in this.form) {
        this.form[key] = this.menuData[key] || '';
      }
      this.initFalse = true;
      this.showTree = false; // 为了先获取tree 数据，然后渲染页面 而添加该属性
      this.getTreeData();
    } else {
      if (JSON.stringify(this.menuData) != '{}') {
        this.form.parentName = this.menuData.menuName;
        this.form.parentId = this.menuData.menuId;
      }
    }
  },
  methods: {
    //  :api-function="this.$api.sysManage.menuTreeData"
    getTreeData() {
      this.$api.sysManage.menuTreeData(this.treeParams).then((res) => {
        var data = transformTree(res.data, {
          pId: 'pid',
          label: 'name',
        });
        this.delTreeNode(data, this.menuData);
        this.treeData = data;
        this.showTree = true;
      });
    },
    delTreeNode(arr, item) {
      for (var i = 0; i < arr.length; i++) {
        var data = arr[i];
        if (data.id == item.menuId) {
          arr.splice(i, 1);
          return;
        } else {
          if (data.children && data.children.length > 0) {
            this.delTreeNode(data.children, item);
          }
        }
      }
    },
    add() {
      this.$api.sysManage.addMenu(this.form).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    eidt() {
      // 编辑的时候，移动至顶级 pid 为0
      if (this.form.parentName === '') {
        this.form.parentId = 0;
      }
      this.$api.sysManage.editMenu(this.form).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: 'success',
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('update');
        }
      });
    },
    saveFn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogType === 'add' ? this.add() : this.eidt();
        }
      });
    },
    handlerNodeClick(treeData) {
      this.form.parentName = treeData.name;
      this.form.parentId = treeData.id;
      this.$refs.selectClassify.blur();
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  width: 90%;
  margin: 0 auto;
}
::v-deep .el-tree > .el-tree-node {
  display: block;
}
</style>
