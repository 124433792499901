<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <div class="hd-search">
        <table-search
          :model="searchParams"
          label-width="80px"
          :isClear="true"
          :flex="'4'"
          @search="searchFn"
        >
          <search-item default label="">
            <el-input
              size="small"
              v-model="searchParams.menuName"
              placeholder="菜单名称"
              clearable
            />
          </search-item>
          <search-item default label="">
            <el-date-picker
              size="small"
              v-model="searchParams.createTime"
              placeholder="创建时间"
              clearable
              value-format="yyyy-MM-dd"
            />
          </search-item>
        </table-search>
      </div>
      <div class="bd-content">
        <div class="table-opt">
          <el-button
            type="primary"
            class="el-icon-circle-plus-outline"
            @click="add({})"
          >
            新 增
          </el-button>
          <el-button
            type="info"
            plain
            icon="el-icon-sort"
            @click="toggleExpandAll"
          >展开/折叠</el-button>
          <!-- <el-button type="warning" class="el-icon-delete" @click="del('more')">
            批量删除
          </el-button> -->
        </div>
        <div class="com-data-table">
          <el-table
            v-if="refreshTable"
            v-loading="loading"
            :data="menuList"
            :default-expand-all="isExpandAll"
            row-key="menuId"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          >
            <el-table-column prop="menuName" label="菜单名称" :show-overflow-tooltip="true" min-width="200"></el-table-column>
            <el-table-column prop="icon" label="图标" width="150">
            </el-table-column>
            <el-table-column prop="orderNum" label="排序" width="60"></el-table-column>
            <el-table-column prop="perms" label="权限标识" :show-overflow-tooltip="true" min-width="130"></el-table-column>
            <el-table-column prop="url" label="组件路径" :show-overflow-tooltip="true" min-width="160"></el-table-column>
            <el-table-column prop="visible" label="菜单状态" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.visible === '0'">启用</span>
                <span v-if="scope.row.visible === '1'">禁用</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime" width="160">
              <!-- <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template> -->
            </el-table-column>
            <el-table-column label="操作" class-name="small-padding fixed-width" width="120">
              <template slot-scope="scope">
                <div class="data-operate">
                  <span class="op-blue" @click="eidt(scope.row)">
                    编辑
                  </span>
                  <span class="op-blue" @click="add(scope.row)">
                    新增
                  </span>
                  <span class="op-red" @click="del(scope.row)">
                    删除
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <add-menu
      v-if="dialogVisible"
      :dialogType="dialogType"
      :dialogVisible.sync="dialogVisible"
      :menuData="menData"
      @update="searchFn"
    />
  </div>
</template>

<script>
import addMenu from './component/menucomponent/addMenu.vue';
import { handleTree } from '@/utils/utils.js';
export default {
  name: 'menuManage',
  components: {
    addMenu,
  },
  data() {
    return {
      // 菜单表格树数据
      menuList: [],
      selectData: [],
      loading: false,
      searchParams: {
        pageNum: 1,
        pageSize: 9999,
      },
      searchAble: false,
      dialogVisible: false,
      dialogType: '',
      menData: {},
      // 是否展开，默认全部折叠
      isExpandAll: false,
      // 重新渲染表格状态
      refreshTable: true,
    };
  },
  mounted() {
    this.tableRef = this.$refs.table;
    this.getList();
  },
  methods: {
    /** 展开/折叠操作 */
    toggleExpandAll() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    /** 查询菜单列表 */
    getList() {
      this.loading = true;
      this.$api.sysManage.queryMenuListData({
        ...this.searchParams,
      }).then(res => {
        var data = res.data.list;
        this.menuList = handleTree(data, "menuId");
        this.loading = false;
      });
    },
    selectAll(params) {
      this.selectData = params;
    },
    select(params) {
      this.selectData = params;
    },
    searchFn() {
      this.getList();
    },
    add(row) {
      this.menData = row;
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    eidt(row) {
      this.menData = row;
      this.dialogType = 'edit';
      this.dialogVisible = true;
    },
    del(params) {
      if (params === 'more' && this.selectData.length === 0) {
        this.$message({
          message: '请先勾选数据',
          type: 'warning',
        });
        return;
      }
      this.$msg.confirm('确认删除菜单？').then(() => {
        this.$api.sysManage
          .removeMenu({
            ids:
              params === 'more'
                ? this.selectData.map((i) => i.menuId).join()
                : params.menuId,
          })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: 'success',
              });
              this.getList();
            }
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .bd-content .com-data-table .el-table .el-table__header th.is-leaf {
  background: rgb(241, 241, 241) !important;
}
</style>
